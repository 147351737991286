import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../components/new/Layout";
import { useRecoilValue } from "recoil";
import { browserState } from "../components/new/ScrollAware";

import FixedBlock from "../components/new/blocks/FixedBlock";
import HeroBlock from "../components/new/blocks/HeroBlock";
import TitleBlock from "../components/new/blocks/TitleBlock";
import PaginationBlock from "../components/new/blocks/PaginationBlock";

import VideoGridFullBlock from "../components/new/blocks/VideoGridFullBlock";

import { extractSocialMetadata } from "../utils/content";

import theme from "../theme";

const VideoList = ({ data, pageContext }) => {
  const { isIE } = useRecoilValue(browserState);
  const content = data.prismic.thought_leadership_page;
  const meta = data.site.siteMetadata;
  const videoCards = data.prismic.allVideo_cards.edges;

  if (!content) {
    return null;
  }

  const [hasSocialMetaData, socialMetadata] = extractSocialMetadata(
    content.body2
  );

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={`${content.meta_title}`}
        meta={[
          {
            name: `description`,
            content: content.meta_description,
          },
          {
            property: `og:title`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.title
              : meta.title,
          },
          {
            property: `og:description`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.description
              : content.meta_description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `image`,
            property: `og:image`,
            content: hasSocialMetaData && socialMetadata.general_card.image,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:site`,
            content: hasSocialMetaData && socialMetadata.twitter_handle,
          },
          {
            name: `twitter:title`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.title
              : meta.title,
          },
          {
            name: `twitter:description`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.description
              : meta.description,
          },
          {
            name: `twitter:image`,
            content: hasSocialMetaData && socialMetadata.twitter_card.image,
          },
        ].concat(meta)}
      />
      <Layout>
        {content.body.map((section, i) => {
          const key = `${section.type}_${i}`;
          const { type, primary: data } = section;

          switch (type) {
            case "image_hero":
              return (
                <FixedBlock
                  index={isIE ? theme.zIndex.background : theme.zIndex.swipe}
                  key={key}
                >
                  <HeroBlock
                    image={data.image_hero_background}
                    title={data.image_hero_heading}
                  />
                </FixedBlock>
              );
            case "intro_block":
              return <TitleBlock title={data.intro_block_call_out} />;
            case "video_grid":
              return (
                <>
                  <VideoGridFullBlock
                    key={key}
                    videos={videoCards}
                    category="thought-leadership"
                  />
                  <PaginationBlock pageInfo={pageContext} />
                </>
              );
          }
        })}
      </Layout>
    </>
  );
};

export default VideoList;

export const query = graphql`
  query videoThoughtLeadershipQuery($first: Int!, $startCursor: String!) {
    prismic {
      thought_leadership_page(lang: "en-gb", uid: "thought-leadership") {
        body {
          ... on PRISMIC_Thought_leadership_pageBodyImage_hero {
            type
            label
            primary {
              image_hero_background
              image_hero_heading
            }
          }
          ... on PRISMIC_Thought_leadership_pageBodyIntro_block {
            type
            label
            primary {
              intro_block_body_copy
              intro_block_call_out
            }
          }
          ... on PRISMIC_Thought_leadership_pageBodyVideo_grid {
            type
            label
          }
        }
        meta_title
        meta_description
      }
      allVideo_cards(
        lang: "en-gb"
        first: $first
        after: $startCursor
        where: { type: "Thought Leadership" }
      ) {
        edges {
          node {
            eyeline
            title
            client_name
            short_copy
            type
            card_image_landscape
            card_image_portrait
            video_id
            hash_id
            _linkType
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
